var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('StandardCardHeader',{attrs:{"go-to-link":'admin-equipment-list',"title":'Create equipment',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}}),_c('v-form',{ref:"form",staticClass:"ma-0 pt-6",attrs:{"lazy-validation":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('Name')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.equipment.name),callback:function ($$v) {_vm.$set(_vm.equipment, "name", $$v)},expression:"equipment.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-select',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","items":_vm.$store.state.warrantCategories,"item-text":function (item) { return _vm.$t(item.name); },"item-value":"key","label":_vm.$t('Process')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.equipment.process),callback:function ($$v) {_vm.$set(_vm.equipment, "process", $$v)},expression:"equipment.process"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-select',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","items":_vm.$store.state.baseStatuses,"item-text":function (item) { return _vm.$t(item.name); },"item-value":"key","label":_vm.$t('Status')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.equipment.status),callback:function ($$v) {_vm.$set(_vm.equipment, "status", $$v)},expression:"equipment.status"}})],1)],1)],1)],1),_c('StandardCardHeader',{attrs:{"go-to-link":'admin-equipment-list',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close' ))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }